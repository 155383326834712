import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TextSection from "../components/text-section";
import Figure from "../components/figure";

import damasceno2019 from "../images/achievements/damasceno-2019.png";
import vandenbossche2018 from "../images/achievements/vandenbossche-2018.png";

import ercLogo from "../images/erc-logo.svg";
import euroflowLogo from "../images/euroflow-logo.svg";
import lumcLogo from "../images/lumc-logo.svg";

function AchievementsPage() {
  return (
    <Layout>
      <SEO
        keywords={[`timascan`, `ERC`, `cancer`, `macrophages`]}
        title="Achievements"
      />
      <section>
        <h1 className="text-4xl mb-4">Achievements</h1>
        <TextSection title="Results">
          A major achievement of the TiMaScan project is the development of an
          antibody panel for detection of different subsets of monocytes by flow
          cytometry. This antibody panel was used to show that in humans, the
          numbers of different monocyte subsets, including classical,
          intermediate and non-classical monocytes, differ depending on age and
          tissue (Figure 1;{" "}
          <a
            href="https://www.ncbi.nlm.nih.gov/pubmed/30890326"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            Damasceno et al., 2019
          </a>
          ).
        </TextSection>

        <Figure
          alt="Figure Damasceno et al. 2019"
          src={damasceno2019}
          className="my-8 md:px-20"
        >
          <b>Figure 1:</b> Relative distribution of monocyte subsets in
          different lymphoid tissues (from{" "}
          <a
            href="https://www.ncbi.nlm.nih.gov/pubmed/30890326"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            Damasceno et al., 2019
          </a>
          ).
        </Figure>

        <TextSection>
          Furthermore, by monitoring different subsets of monocytes in
          peripheral blood following controlled tissue damage (total hip
          arthroplasty), we showed that changes in numbers of classical
          monocytes, which followed the same temporal pattern as total
          monocytes, are different from the changes in numbers of non-classical
          monocytes (Figure 2;{" "}
          <a
            href="https://www.ncbi.nlm.nih.gov/pubmed/30290225"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            Van den Bossche et al., 2018
          </a>
          ).
        </TextSection>

        <Figure
          alt="Figure Van den Bossche et al. 2019"
          src={vandenbossche2018}
          className="my-8 md:px-10"
        >
          <b>Figure 2:</b> Acute tissue damage induces differential dynamics of
          classical and non-classical monocytes in peripheral blood (from{" "}
          <a
            href="https://www.ncbi.nlm.nih.gov/pubmed/30290225"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700"
          >
            Van den Bossche et al., 2018
          </a>
          ).
        </Figure>

        <TextSection>
          A{" "}
          <a
            href="https://patents.google.com/patent/US20140024019A1/en"
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue-700"
          >
            patent
          </a>{" "}
          that covers the detection of monocyte subsets to monitor disruption of
          tissue homeostasis was filed and granted, and has been licensed to{" "}
          <a
            href="https://www.cytognos.com/ngf-solutions/timascan-monocyte-subsets-tube/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-blue-700"
          >
            Cytognos
          </a>
          .
        </TextSection>

        <TextSection title="Publications">
          <ul className="list-disc list-inside">
            <li>
              <a
                href="https://www.frontiersin.org/articles/10.3389/fimmu.2022.935879/full"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                van der Pan K, de Bruin-Versteeg S, Damasceno D,
                Hernández-Delgado A, van der Sluijs-Gelling AJ, van den Bossche
                WBL, de Laat IF, Díez P, Naber BAE, Diks AM, Berkowska MA, de
                Mooij B, Groenland RJ, de Bie FJ, Khatri I, Kassem S, de Jager
                AL, Louis A, Almeida J, van Gaans-van den Brink JAM, Barkoff AM,
                He Q, Ferwerda G, Versteegen P, Berbers GAM, Orfao A, van Dongen
                JJM, Teodosio C. Development of a standardized and validated
                flow cytometry approach for monitoring of innate myeloid immune
                cells in human blood. Front Immunol 2022; 13:935879.{" "}
              </a>
            </li>
            <li>
              <a
                href="https://www.frontiersin.org/articles/10.3389/fmed.2022.997305/full"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                van der Pan K, Kassem S, Khatri I, de Ru AH, Janssen GMC,
                Tjokrodirijo RTN, Al Makindji F, Stavrakaki E, de Jager AL,
                Naber BAE, de Laat IF, Louis A, van den Bossche WBL, Vogelezang
                LB, Balvers RK, Lamfers MLM, van Veelen PA, Orfao A, van Dongen
                JJM, Teodosio C, Díez P. Quantitative proteomics of small
                numbers of closely-related cells: Selection of the optimal
                method for a clinical setting. Front Med 2022; 9:997305.{" "}
              </a>
            </li>
            <li>
              <a
                href="https://doi.org/10.1021/acs.jproteome.1c00321"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Kassem S, van der Pan K, de Jager AL, Naber BAE, de Laat IF,
                Louis A, van Dongen JJM, Teodosio C, Díez P. Proteomics for low
                cell numbers: How to optimize the sample preparation workflow
                for mass spectrometry analysis. J Proteome Res 2021;
                20:4217-4230.{" "}
              </a>
            </li>
            <li>
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/30890326"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Damasceno D, Teodosio C, van den Bossche WBL, Perez-Andres M,
                Arriba-Méndez S, Muñoz-Bellvis L, Romero A, Blanco JF, Remesal
                A, Puig N, Matarraz S, Vicente-Villardón JL, van Dongen JJM,
                Almeida J, Orfao A; TiMaScan Study Group. Distribution of
                subsets of blood monocytic cells throughout life. J Allergy Clin
                Immunol 2019; 144:320-323.e6
              </a>
            </li>
            <li>
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/30290225"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Van den Bossche WBL, Rykov K, Teodosio C, Ten Have BLEF, Knobben
                BAS, Sietsma MS, Josiassen K, de Bruin-Versteeg S, Orfao A, van
                Dongen JJM, van Raay JJAM. Flow cytometric assessment of
                leukocyte kinetics for the monitoring of tissue damage. Clin
                Immunol 2018; 197:224-230
              </a>
            </li>
            <li>
              <a
                href="https://www.ncbi.nlm.nih.gov/pubmed/29796615"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Van den Bossche WBL, Kleijn A, Teunissen CE, Voerman JSA,
                Teodosio C, Noske DP, van Dongen JJM, Dirven CMF, Lamfers MLM.
                Oncolytic virotherapy in glioblastoma patients induces a tumor
                macrophage phenotypic shift leading to an altered glioblastoma
                microenvironment. Neuro Oncol 2018; 20:1494-1504
              </a>
            </li>
          </ul>
        </TextSection>

        <TextSection title="Other dissemination">
          <ul className="list-disc list-inside">
            <li>
              Assessment of methods for lysosome fraction enrichment: an
              approach for lysosomal MS-based studies. A.L. de Jager.
              Presentation at Immunology Science Retreat, 30 September 2022,
              Leiden, The Netherlands.
            </li>
            <li>
              Mass spectrometry analysis of highly purified human colon
              epithelial and stem cell populations in normal colon and
              colorectal cancer. A.L. de Jager. Poster at Summer School Advanced
              Proteomics, 1-5 August 2022, Brixen, Italy.
            </li>
            <li>
              Quantity-limited maturational-related cells: How to perform
              reliable proteomics profiling in a medical-oriented setting. K.
              van der Pan. Presentation at Nederlands Proteomics Platform
              meeting, 24 May 2022, Utrecht, The Netherlands.
            </li>
            <li>
              Quantitative proteomic landscape of quantity-limited human
              monocytes and glioma macrophages. K. van der Pan. Poster at
              Science Retreat of Department of Immunology, 21 November 2021,
              Leiden, The Netherlands.
            </li>
            <li>
              Characterizing lysosomal proteases to design an in silico
              post-digestion peptide prediction tool. A.L. de Jager. Poster at
              BSPR Interact, 6-8 July 2021, Online.
            </li>
            <li>
              Dissection of the monocyte & dendritic cell compartment in blood
              and bone marrow. K. van der Pan. Presentation at 8th ESLHO
              Symposium, 7 November 2019, Ghent, Belgium.
            </li>
            <li>
              Development of a 14-color flow cytometry tube for standardized
              identification of innate myeloid cells in human peripheral blood.
              K. van der Pan. Poster at Keystone Symposium Myeloid Cells, 24-28
              February 2019, Santa Fe, USA.
            </li>
            <li>
              Dissection of the monocyte-macrophage maturation pathway - as an
              immune monitoring tool. K. van der Pan. Presentation at Science
              Retreat of Department of Immunology, 31 May 2018, Leiden, The
              Netherlands.
            </li>
            <li>
              <a
                href="https://www.ziekten.nl/zoeklicht-op-het-immuunsysteem/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Zoeklicht op het immuunsysteem. Ziekten.nl, 7 January 2018.
              </a>
            </li>
            <li>
              <a
                href="https://www.oncologie.nu/nieuws/25-miljoen-voor-een-wild-idee/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                2,5 Miljoen voor een ‘wild’ idee. Oncologie.nu, 3 August 2016.
              </a>
            </li>
            <li>
              <a
                href="https://www.lumc.nl/over-het-lumc/nieuws/2016/april/aanstelling-van-dongen/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-700"
              >
                Kanker opsporen via de ‘vuilniswagens’ van het afweersysteem.
                Press release LUMC, 18 April 2016.
              </a>
            </li>
          </ul>
        </TextSection>

        <TextSection title="TiMaScan project progress meetings">
          <ul className="list-disc list-inside">
            <li>15th TiMaScan meeting, 1-2 July 2019, Salamanca, Spain</li>
            <li>
              14th TiMaScan meeting, 22-23 November 2018, Leiden, The
              Netherlands
            </li>
            <li>
              13th TiMaScan meeting, 30-31 May 2018, Leiden, The Netherlands
            </li>
            <li>12th TiMaScan meeting, 29-30 August 2017, Salamanca, Spain</li>
            <li>
              11th TiMaScan meeting, 14-15 February 2017, Leiden, The
              Netherlands
            </li>
            <li>
              10th TiMaScan meeting, 19-20 November 2016, Salamanca, Spain
            </li>
          </ul>
        </TextSection>
      </section>

      <div className="md:flex justify-between my-10">
        <div className="md:flex-none flex justify-center">
          <img alt="ERC logo" className="w-auto h-20" src={ercLogo} />
        </div>
        <div className="md:flex-none flex justify-center md:m-0 mt-10">
          <img alt="LUMC logo" className="h-20 w-auto" src={lumcLogo} />
        </div>
        <div className="md:flex-none flex justify-center md:m-0 mt-10">
          <img alt="EuroFlow logo" className="h-20" src={euroflowLogo} />
        </div>
      </div>
    </Layout>
  );
}

export default AchievementsPage;
